





















import { IconChats } from '@tada/icons'
import Row from '@/components/TeamsBar/Row.vue'
import store from '@/store'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    Badge: () => import('@/components/TeamsBar/Badge.vue'),
    IconChats,
    Row,
  },
})
export default class ChatsBtn extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  }) private readonly expanded!: boolean

  private get unread (): number {
    return store.getters.chatsTotalUnreadNum
  }

  private get noticed (): boolean {
    return store.getters.chatsWasNoticed
  }
}
