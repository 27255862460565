import { render, staticRenderFns } from "./Item.vue?vue&type=template&id=7bde7606&scoped=true&"
import script from "./Item.vue?vue&type=script&lang=ts&"
export * from "./Item.vue?vue&type=script&lang=ts&"
import style0 from "./Item.vue?vue&type=style&index=0&id=7bde7606&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bde7606",
  null
  
)

export default component.exports
import {QItem,QItemSection,QIcon,QBtn,QTooltip,QMenu,QList,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QItem,QItemSection,QIcon,QBtn,QTooltip,QMenu,QList})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
