













































import { IconChevronRight } from '@tada/icons'
import IconLogoText from '@/components/TeamsBar/icons/IconLogoText.vue'
import Row from '@/components/TeamsBar/Row.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    IconChevronRight,
    IconLogoText,
    Row,
  },
})
export default class Header extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  }) private readonly expanded!: boolean

  private get logo (): string {
    const { custom_app_icon_name: iconName } = window.FEATURES
    if (iconName === 'aero') return 'img:/assets/logo-aero.svg'
    return 'img:/assets/logo.svg'
  }
}
