



































import { Component, Prop, Vue } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'

@Component
export default class Row extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  }) private readonly expanded!: boolean

  @Prop({
    type: Object,
    default: null,
  }) private readonly href!: RawLocation | null

  @Prop({
    type: String,
    default: null,
  }) private readonly tooltip!: string | null
}
