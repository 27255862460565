

































































































































































































































import BaseAvatar from '@/components/UI/BaseAvatar.vue'
import { transformEntityName } from '@/utils'
import { Team } from '@tada-team/tdproto-ts'
import { QMenu, date } from 'quasar'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'

import { matError } from '@quasar/extras/material-icons'
import { billingStore, teamsStore } from '@/store'

@Component({
  components: {
    Badge: () => import('@/components/TeamsBar/Badge.vue'),
    BaseAvatar,
    IconCrown: () => import('@tada/icons/dist/IconCrown.vue'),
    IconCrownFilled:
      () => import('@tada/icons/dist/IconCrownFilled.vue'),
    IconInfo: () => import('@tada/icons/dist/IconInfo.vue'),
    IconMore: () => import('@tada/icons/dist/IconMore.vue'),
    IconBlocked: () => import('@tada/icons/dist/IconBlocked.vue'),
  },
})
export default class Item extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  }) private readonly expanded!: boolean

  @Prop({
    type: Object,
    required: true,
  }) private readonly team!: Team

  @Prop({
    type: Boolean,
    default: false,
  }) private readonly isCurrent!: boolean

  @Ref() menu!: QMenu

  private imageSize = 26

  // icons signatures
  warningIcon!: string

  created () {
    this.warningIcon = matError
  }

  private onMenuShow (): void {
    this.$root.$once('teamsBar-teamList-scrolled', this.menu.hide)
  }

  private onMenuToggleClick (): void {
    this.isCurrent ? this.menu.show() : this.$emit('openProfileClick')
  }

  private get isAdmin (): boolean {
    return this.team.me.status === 'admin'
  }

  private get isOwner (): boolean {
    return this.team.me.status === 'owner'
  }

  private get hasRights (): boolean {
    return this.isAdmin || this.isOwner
  }

  private get link (): RawLocation | null {
    if (this.isCurrent || this.team.needConfirmation) return null
    return `${location.origin}/${this.team.uid}/`
  }

  private get name (): string {
    return transformEntityName(this.team.name)
  }

  private get unread (): number {
    if (this.isCurrent) return 0

    const { unread: { direct, group, task } } = this.team
    return direct.messages + group.messages + task.messages
  }

  private get isTeamBlocked (): boolean {
    return this.team.accountStatus === 'PERSONAL_ACCOUNT_STATUS_SUSPENDED'
  }

  private get noticed (): boolean {
    if (this.isCurrent) return false

    const { unread: { direct, group, task } } = this.team

    return Boolean(
      direct.noticeMessages ||
      group.noticeMessages ||
      task.noticeMessages,
    )
  }

  private get isDev () {
    return window.FEATURES.is_testing
  }

  get daysUntilBlocking () {
    const nowDate = new Date()
    const today = new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate())
    const nextBillingDateStr = billingStore.state.teamAccount?.nextBillingDate

    if (nextBillingDateStr?.length) {
      const nextBillingDate = new Date(nextBillingDateStr!.slice(0, 10))

      const timeDiff = Math.abs(nextBillingDate.getTime() - today.getTime())
      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))

      return diffDays
    } else {
      return -1
    }
  }

  get isNotCurrentTeam () {
    return teamsStore.getters.currentTeam.uid !== this.team.uid
  }

  private get nextPayment (): string {
    if (!billingStore.state.teamAccount?.nextBillingDate || !date.isValid(billingStore.state.teamAccount?.nextBillingDate)) return ''
    const nextBillingDate = new Date(billingStore.state.teamAccount?.nextBillingDate)
    const dateText = nextBillingDate.toLocaleString(this.$i18n.locale, {
      year: 'numeric',
      day: 'numeric',
      month: 'numeric',
    })

    return this.$t('blocking.activedTo',
      { day: dateText }).toString()
  }

  private onOpenDebugClick () {
    const payload = {
      title: 'Team debug',
      content: this.team,
    }

    this.$q.dialog({
      component: () => import('@/components/Modals/DebugModal.vue'),
      parent: this,
      payload,
    })
  }
}
