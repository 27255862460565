





































import Row from '@/components/TeamsBar/Row.vue'
import { MenuItem } from '@/components/TeamsBar/types'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    Row,
  },
})
export default class Menu extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  }) private readonly expanded!: boolean

  @Prop({
    type: Boolean,
    required: true,
  }) private readonly blocked!: boolean

  @Prop({
    type: Array,
    required: true,
  }) private readonly items!: ReadonlyArray<MenuItem>
}
