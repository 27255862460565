






















































import {
  goalTeamsBarCollapseClick,
  goalTeamsBarCreateTeamClick,
  goalTeamsBarExpandClick,
  goalTeamsBarSupportClick,
  goalTeamsBarDashboardOpen,
  goalTeamsBarDashboardClose,
  goalTeamsBarCalendarOpen,
  goalTeamsBarCalendarClose,
} from '@/analytics'
import api from '@/api/v3'
import ChatsBtn from '@/components/TeamsBar/ChatsBtn.vue'
import Footer from '@/components/TeamsBar/Footer/index.vue'
import Header from '@/components/TeamsBar/Header.vue'
import Menu from '@/components/TeamsBar/Menu.vue'
import Row from '@/components/TeamsBar/Row.vue'
import TeamList from '@/components/TeamsBar/TeamList/index.vue'
import { MenuItem } from '@/components/TeamsBar/types'
import IconLayoutGrid from '@/components/UI/icons/IconLayoutGrid.vue'
import i18n from '@/i18n'
import {
  chatsBarStore,
  contactsStore,
  meetingsStore,
  tasksStore,
  teamsStore,
  uiStore,
} from '@/store'
import { hasCyrillic, move } from '@/utils'
import { Team } from '@tada-team/tdproto-ts'
import { IconCalendarWithDay, IconChats } from '@tada/icons'
import { Component, Vue } from 'vue-property-decorator'
import { Location } from 'vue-router'

@Component({
  components: {
    ChatsBtn,
    Footer,
    Header,
    Menu,
    Row,
    TeamList,
  },
})
export default class TeamsBar extends Vue {
  private expanded = false
  private lastRoute: Location | null = null

  created () {
    this.loadMeetingsCount()
  }

  private onClickOutside (e: any): void {
    if (e.target.matches('.teams-bar *')) return
    if (e.target.matches('.team-list__menu *')) return

    // Fix for ScrollArea bar
    if (e.target.matches('.teams-bar .q-scrollarea__bar *')) return
    if (e.target.matches('.teams-bar .q-scrollarea__thumb *')) return
    if (e.target.matches('html')) return

    this.expanded = false
  }

  beforeDestroy (): void {
    window.removeEventListener('click', this.onClickOutside)
  }

  private get menuItems (): MenuItem[] {
    const items: MenuItem[] = []
    if (uiStore.getters.showTaskDeskRelated) items.push(this.dashboardMenuItem)
    if (!this.currentTeam.singleGroup) items.push(this.calendarMenuItem)
    return items
  }

  private get isTeamBlocked (): boolean {
    return teamsStore.getters.currentTeam.accountStatus === 'PERSONAL_ACCOUNT_STATUS_SUSPENDED'
  }

  private get dashboardMenuItem (): MenuItem {
    const label = this.$t('teamsBar.menu.dashboard').toString()

    const tooltip = this.$route.name === 'Dashboard'
      ? this.$t('teamsBar.menu.dashboardClose').toString()
      : label

    const href = {
      name: 'Dashboard',
      params: {
        teamId: this.currentTeam.uid,
        groupBy: uiStore.getters.currentDeskTabKey ?? 'section',
      },
    }

    return {
      icon: IconLayoutGrid,
      label,
      tooltip,
      counter: tasksStore.getters.count,
      href,
      action: () => {
        if (this.isTeamBlocked) return
        (this.$route.name === href.name)
          ? goalTeamsBarDashboardClose(this.expanded)
          : goalTeamsBarDashboardOpen(this.expanded)

        this.saveOrPushRoute(href)
      },
    }
  }

  private get calendarMenuItem (): MenuItem {
    const label = this.$t('teamsBar.menu.calendar').toString()

    const tooltip = this.$route.name === 'Calendar'
      ? this.$t('teamsBar.menu.calendarClose').toString()
      : this.isTeamBlocked ? this.$t('teamsBar.menu.calendarBlockedTooltip').toString()
        : this.$tc('teamsBar.menu.calendarTooltip', this.meetingsCount).toString()

    const href = {
      name: 'Calendar',
      params: {
        teamId: this.currentTeam.uid,
      },
    }

    return {
      icon: IconCalendarWithDay,
      label,
      tooltip,
      counter: this.meetingsCount,
      href,
      action: () => {
        if (this.isTeamBlocked) return
        (this.$route.name === href.name)
          ? goalTeamsBarCalendarClose(this.expanded)
          : goalTeamsBarCalendarOpen(this.expanded)

        this.saveOrPushRoute(href)
      },
    }
  }

  private get meetingsCount (): number | undefined {
    if (this.currentTeam.singleGroup) return
    return meetingsStore.state.count
  }

  private get chatsMenuItem (): MenuItem {
    return {
      icon: IconChats,
      label: this.$t('teamsBar.menu.chatList').toString(),
      alwaysActive: true,
    }
  }

  private get currentTeam (): Team {
    return teamsStore.getters.currentTeam
  }

  private get teams (): Team[] {
    return [...teamsStore.getters.teams].sort((a, b) => {
      if (a.needConfirmation !== b.needConfirmation) {
        return a.needConfirmation ? 1 : -1
      }

      if (i18n.locale === 'ru') {
        const aHasCyrillic = hasCyrillic(a.name)
        const bHasCyrillic = hasCyrillic(b.name)
        if (aHasCyrillic !== bHasCyrillic) return aHasCyrillic ? -1 : 1
      }

      return a.name.localeCompare(b.name)
    })
  }

  private loadMeetingsCount () {
    if (this.currentTeam.singleGroup) return
    meetingsStore.actions.loadMeetingsCount()
  }

  private onAddContactClick (team: Team): void {
    if (!team.me.canAddToTeam) return
    // TODO: fix modal
    uiStore.actions.showModal({
      instance: 'AddContact',
    })
  }

  private onChangeTeam (uid: string): void {
    if (uid !== this.currentTeam.uid) {
      move(uid)
      chatsBarStore.mutations.setRosterIsReady(false)
    }
    this.loadMeetingsCount()
  }

  private onCreateTeamClick (): void {
    goalTeamsBarCreateTeamClick(this.expanded)
    uiStore.actions.showModal({
      instance: 'TeamCreation',
      payload: { mono: false },
    })
  }

  private onEditIntegrationsClick (team: Team): void {
    console.log(`Click on edit integrations btn for team: "${team.name}"`)
    // TODO: fix modal
    uiStore.actions.showModal({
      instance: 'Integrations',
    })
  }

  private onInviteAcceptClick (team: Team): void {
    move(team.uid)
  }

  private onInviteDeclineClick (team: Team): void {
    api.contacts.delete(team.me.jid, team.uid)
  }

  private onOpenProfileClick (team: Team): void {
    uiStore.actions.switchRightBarInstance({
      instance: 'team-profile',
      payload: team.uid,
    })
  }

  private onOpenSettingsClick (team: Team): void {
    uiStore.actions.showModal({
      instance: 'TeamSettingsModal',
      payload: team.uid,
    })
  }

  private onSupportClick (): void {
    goalTeamsBarSupportClick(this.expanded)
    this.$router.push({
      name: 'Chat',
      params: {
        teamId: this.currentTeam.uid,
        jid: contactsStore.getters.supportChatId,
      },
    })
  }

  private onToggle (): void {
    this.expanded ? goalTeamsBarCollapseClick() : goalTeamsBarExpandClick()

    this.expanded = !this.expanded
    this.expanded
      ? window.addEventListener('click', this.onClickOutside)
      : window.removeEventListener('click', this.onClickOutside)
  }

  private onOpenTeamsbar (): void {
    this.expanded = !this.expanded
  }

  private saveOrPushRoute (href: Location): void {
    const { name, params, query } = this.$route // "previous" route
    if (!name) return

    // Click to btn with same route. For example, click to "Dashboard" btn when
    // you are on "Dashboard" page
    if (name === href.name) {
      this.$router.push(this.lastRoute ?? { name: 'Main' })
      this.lastRoute = null
      return
    }

    // Click to one of menu items. So, we just push route without saving
    const menuItemsNames = this.menuItems.map(item => item.href?.name)
    if (menuItemsNames.includes(name)) {
      this.$router.push(href)
      return
    }

    // Save "previous" route if it's not one of menu items for future use
    this.lastRoute = { name, params, query }
    this.$router.push(href)
  }

  get canCreateTeam () {
    return teamsStore.getters.currentTeam.me.canCreateTeam || false
  }
}
