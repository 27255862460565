















































import Item from '@/components/TeamsBar/TeamList/Item.vue'
import { Team } from '@tada-team/tdproto-ts'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    Item,
  },
})
export default class TeamList extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  }) private readonly expanded!: boolean

  @Prop({
    type: Array,
    required: true,
  }) private readonly teams!: Team[]

  @Prop({
    type: Object,
    required: true,
  }) private readonly currentTeam!: Team

  private onScroll (): void {
    this.$root.$emit('teamsBar-teamList-scrolled')
  }

  private get items (): Array<Team | 'separator'> {
    const teams = this.teams.sort((a, b) => {
      if (a.needConfirmation === b.needConfirmation) return 0
      return a.needConfirmation ? 1 : -1
    })

    const items: Array<Team | 'separator'> = teams
    const index = teams.findIndex(t => t.needConfirmation)
    if (index > 0) items.splice(index, 0, 'separator')

    return items
  }

  private readonly thumbStyle: Partial<CSSStyleDeclaration> = {
    right: '4px',
    borderRadius: '4px',
    backgroundColor: '#505050',
    width: '4px',
    opacity: '1',
  }
}
