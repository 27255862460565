

















































import { goalTeamsBarHelpClick } from '@/analytics'
import type Menu from '@/components/TeamsBar/Footer/Menu.vue'
import Row from '@/components/TeamsBar/Row.vue'
import { IconHelpCircle } from '@tada/icons'
import IconPlus from '@tada/icons/dist/IconPlus.vue'
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'

@Component({
  components: {
    IconHelpCircle,
    IconPlus,
    Menu: () => import('@/components/TeamsBar/Footer/Menu.vue'),
    Row,
  },
})
export default class Footer extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  }) private readonly expanded!: boolean

  @Prop({
    type: Boolean,
    required: true,
  }) private readonly canCreateTeam!: boolean

  @Ref() helpMenu!: Menu

  private onHelpClick (): void {
    goalTeamsBarHelpClick(this.expanded)
    this.helpMenu.show()
  }

  private onCreateTeam () {
    if (!this.canCreateTeam) return
    this.$emit('createTeamClick')
  }
}
